@font-face {
  font-family: "binasea";
  src: url("./font/binasea.eot");
  src:
    url("./font/binasea.eot") format("embedded-opentype"),
    url("./font/binasea.ttf") format("truetype"),
    url("./font/binasea.woff") format("woff"),
    url("./font/binasea.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "binasea" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-fl-tik-tok-2:before {
  content: "\e920";
}
.icon-fl-vt:before {
  content: "\e91f";
}
.icon-fl-tik-tok:before {
  content: "\e916";
}
.icon-fl-google-2:before {
  content: "\e939";
}
.icon-fl-down-2:before {
  content: "\e93e";
}
.icon-fl-buy:before {
  content: "\e934";
}
.icon-fl-credit-card:before {
  content: "\e935";
}
.icon-fl-discount:before {
  content: "\e936";
}
.icon-fl-heart-filled:before {
  content: "\e937";
}
.icon-fl-logout:before {
  content: "\e938";
}
.icon-fl-search-filled:before {
  content: "\e93a";
}
.icon-fl-sort-filled:before {
  content: "\e93b";
}
.icon-fl-star:before {
  content: "\e93c";
}
.icon-fl-users-filled:before {
  content: "\e93d";
}
.icon-fl-check-1:before {
  content: "\e921";
}
.icon-fl-coolicon:before {
  content: "\e922";
}
.icon-coolicon11:before {
  content: "\e923";
}
.icon-fl-facebook:before {
  content: "\e925";
}
.icon-fl-google:before {
  content: "\e926";
}
.icon-google11:before {
  content: "\e927";
}
.icon-fl-tag:before {
  content: "\e928";
}
.icon-fl-file-1:before {
  content: "\e929";
}
.icon-fl-icon-11:before {
  content: "\e92a";
}
.icon-fl-icon-22:before {
  content: "\e92b";
}
.icon-fl-dots:before {
  content: "\e92c";
}
.icon-fl-reload-1:before {
  content: "\e92d";
}
.icon-twitch:before {
  content: "\e92e";
}
.icon-fl-mess:before {
  content: "\e92f";
}
.icon-fl-clock:before {
  content: "\e930";
}
.icon-fl-down:before {
  content: "\e931";
}
.icon-fl-vector-left:before {
  content: "\e932";
}
.icon-fl-vector-right:before {
  content: "\e933";
}
.icon-fl-fire:before {
  content: "\e900";
}
.icon-fl-bag:before {
  content: "\e901";
}
.icon-fl-bookmark .path1:before {
  content: "\e902";
  color: rgb(255, 255, 255);
  opacity: 0.4;
}
.icon-fl-bookmark .path2:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-fl-category .path1:before {
  content: "\e904";
  color: rgb(255, 255, 255);
}
.icon-fl-category .path2:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-fl-category .path3:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.4;
}
.icon-fl-category .path4:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-fl-group .path1:before {
  content: "\e908";
  color: rgb(255, 255, 255);
}
.icon-fl-group .path2:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.4;
}
.icon-fl-group .path3:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.4;
}
.icon-fl-heart:before {
  content: "\e90b";
}
.icon-fl-icon-1 .path1:before {
  content: "\e90c";
  color: rgb(255, 255, 255);
  opacity: 0.4;
}
.icon-fl-icon-1 .path2:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-fl-icon-2 .path1:before {
  content: "\e90e";
  color: rgb(255, 255, 255);
  opacity: 0.4;
}
.icon-fl-icon-2 .path2:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-fl-icon-2 .path3:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.4;
}
.icon-fl-icon-3 .path1:before {
  content: "\e911";
  color: rgb(255, 255, 255);
}
.icon-fl-icon-3 .path2:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.4;
}
.icon-fl-icon-4 .path1:before {
  content: "\e913";
  color: rgb(255, 255, 255);
  opacity: 0.4;
}
.icon-fl-icon-4 .path2:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-fl-icon-4 .path3:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(151, 142, 253);
}
.icon-fl-wallet:before {
  content: "\e917";
}
.icon-fl-icon:before {
  content: "\e918";
}
.icon-fl-image .path1:before {
  content: "\e919";
  color: rgb(255, 255, 255);
}
.icon-fl-image .path2:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
  opacity: 0.4;
}
.icon-fl-note:before {
  content: "\e91b";
}
.icon-fl-reload:before {
  content: "\e91c";
}
.icon-fl-rocket:before {
  content: "\e91d";
}
.icon-fl-send:before {
  content: "\e91e";
}

.set-message-id {
  margin: 5px;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  max-width: 125px;
}

.view-message-button {
  margin: 5px;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  max-width: 125px;
  background-color: #3749e9;
  color: #3749e9;
}

.discord-strategy {
  margin-bottom: 2px;
}
